import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../../components/Layout';
import BasicPageSection from "../../components/BasicPageSection";

const OurValuesPage = ({data}) => {
    const {contentfulBasicPageSection} = data;
    return (
        <>
            <Layout>
                <main>
                    <BasicPageSection basicPage={contentfulBasicPageSection} />
                </main>
            </Layout>
        </>
    )
}

export default OurValuesPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
  query ($locale: String!) {
    contentfulBasicPageSection(
      contentful_id: {eq: "7isNSezShcZMxsyRUyfBso"}
      node_locale: {eq: $locale}
    ) {
      ... BasicPageFields
    }
  }
`;